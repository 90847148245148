import React, { ReactNode } from "react";
import { Controller } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import Slider from "@mui/material/Slider";
import { SliderOwnProps } from "@mui/material/Slider/Slider";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { useFormContext } from "../forms2/Form";

const useStyles = makeStyles()(() => ({
  container: {
    padding: "0 8px",
  },
  markLabel: {
    fontSize: 12,
    whiteSpace: "normal",
    textAlign: "center",
    "&:nth-of-type(4)": {
      left: "calc(0% + 12px) !important",
    },
    "&:nth-last-of-type(2)": {
      left: "calc(100% - 12px) !important",
    },
  },
}));

export interface SliderFieldProps {
  title: string;
  name: string;
  helperText?: ReactNode;
  disabled?: boolean;
  defaultValue?: string;
  withValueIndicator?: boolean;
  tooltip?: ReactNode;
  sliderProps?: SliderOwnProps;
}

function SliderField({
  title,
  name,
  helperText,
  tooltip,
  defaultValue,
  withValueIndicator,
  disabled,
  sliderProps,
}: SliderFieldProps) {
  const { classes } = useStyles();
  const { form, disabled: formDisabled, id } = useFormContext();
  const inputId = `${id}-${name}`;

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue ?? 0}
      render={({ field, fieldState }) => {
        return (
          <div id={inputId} style={{ display: "flex", alignItems: "center", marginBottom: 32 }}>
            <div style={{ marginTop: 4, flex: 1 }}>
              <FormInputLabel
                id={`${inputId}-title`}
                label={title}
                optional={false}
                tooltip={tooltip}
                tooltipPlacement="bottom"
                forId={`${inputId}-slider-input`}
              />
              <div className={classes.container}>
                <Slider
                  value={field.value}
                  onChange={(_, newValue) => {
                    field.onChange(newValue);
                  }}
                  disabled={disabled || formDisabled}
                  classes={{
                    markLabel: classes.markLabel,
                  }}
                  slotProps={{
                    input: {
                      id: `${inputId}-slider-input`,
                    },
                  }}
                  {...sliderProps}
                />
              </div>
              <FormHelperText style={{ minHeight: 20 }} error={!!fieldState.error}>
                {fieldState.error?.message || helperText}
              </FormHelperText>
            </div>
            {withValueIndicator && (
              <Typography
                fontSize={18}
                style={{ width: 200, textAlign: "right", position: "relative", top: -10 }}
              >
                <strong>{sliderProps?.value ?? field.value}</strong>
              </Typography>
            )}
          </div>
        );
      }}
    />
  );
}

export default SliderField;
