import React from "react";

import Link from "@mui/material/Link";
import { makeStyles } from "tss-react/mui";

import FormAccordion from "../../../common/components/FormAccordion";
import ExchangeIcon from "../../../common/components/icons/overview/ExchangeIcon";
import { FormFactory } from "../../../common/utils/forms/formFactory";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 32,
  },
  icon: {
    border: `1px solid ${theme.palette.secondary.main}`,
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 32,
    color: theme.palette.secondary.main,
    marginRight: 8,
  },
}));

export const identitiesWithTokenExchange = ["oidc", "auth0", "okta", "azure"];

export interface IdentityConfigurationProps {
  id?: string;
  inEdit?: boolean;
  formFactory: FormFactory;
  data: any;
}

export const CommonIdpConfig = (props: IdentityConfigurationProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {props.inEdit && identitiesWithTokenExchange.includes(props.data.method) && (
        <FormAccordion
          title="Token Exchange"
          id={props.id || "token-exchange"}
          isActive={props.data.token_exchange_settings?.enabled}
        >
          {props.formFactory.createComplexToggle({
            id: "token-exchange",
            name: "token_exchange_settings.enabled",
            subtitle: (
              <span>
                Issue tokens in exchange for tokens issued by this identity provider
                <Link
                  href="https://cloudentity.com/developers/basics/oauth-grant-types/token-exchange/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "block", marginTop: 8 }}
                >
                  Read more about OAuth token exchange.
                </Link>
              </span>
            ),
            img: (
              <div className={classes.icon}>
                <ExchangeIcon />
              </div>
            ),
            style: { padding: 0, marginBottom: 0, border: "none" },
          })}
        </FormAccordion>
      )}
    </div>
  );
};
