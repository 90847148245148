import React, { CSSProperties, ReactNode, useState } from "react";
import { Controller } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Icon } from "react-feather";
import { makeStyles } from "tss-react/mui";

import ConfirmationDialog from "../../components/ConfirmationDialog";
import FormInputLabel from "../../components/FormInputLabel";
import Switch from "../../components/Switch";
import { useFormContext } from "../forms2/Form";

const useStyles = makeStyles()(theme => ({
  root: {
    padding: "24px 16px",
    border: "1px solid #ECECEC",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  left: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: 12,
    color: theme.palette.secondary.light,
    textTransform: "uppercase",
  },
  subtitle: {
    marginTop: 8,
    fontSize: 12,
    color: theme.palette.secondary.light,
  },
  highlighted: {
    border: `solid 1px ${theme.palette.primary.main}`,
    boxShadow: "0 0 0 rgba(0, 131, 255, 0.4)",
    animation: "$pulse 2s 2",
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(0, 131, 255, 0.4)",
    },
    "70%": {
      boxShadow: "0 0 0 10px rgba(0, 131, 255, 0)",
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(0, 131, 255, 0)",
    },
  },
  avatar: {
    background: theme.palette.primary.main,
  },
}));

export interface ComplexToggleProps {
  id?: string;
  name: string;
  label?: string;
  title?: string;
  subtitle: string | ReactNode;
  style?: CSSProperties;
  icon?: Icon | string;
  img?: ReactNode;
  highlighted?: boolean;
  disabled?: boolean;
  withConfirmation?: boolean;
  confirmation?: {
    title: string;
    content: string | ReactNode;
    warningItems?: string[];
    confirmText: string;
  };
  onChange?: (checked: boolean) => void;
}

export default function ComplexToggle({
  id,
  name,
  label,
  title,
  subtitle,
  icon,
  img,
  style = {},
  highlighted = false,
  disabled = false,
  withConfirmation = false,
  confirmation = { title: "", content: "", warningItems: [], confirmText: "" },
  onChange,
}: ComplexToggleProps) {
  const { cx, classes } = useStyles();
  const [dialog, setDialog] = useState(false);
  const { form, disabled: formDisabled, progress, id: formId } = useFormContext();
  const htmlId = id || formId;

  const Icon = typeof icon === "string" ? undefined : icon;

  return (
    <div id={`${htmlId}-complex-toggle`}>
      {label && <FormInputLabel id={`${htmlId}-label`} label={label} />}
      <div className={cx(classes.root, highlighted && classes.highlighted)} style={style}>
        <div className={classes.left}>
          {icon && (
            <Avatar className={classes.avatar}>
              {Icon ? <Icon /> : <img src={icon as string} alt="icon" />}
            </Avatar>
          )}
          {img}
          <div style={{ marginLeft: 12 }}>
            {title && <Typography className={classes.title}>{title}</Typography>}
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </div>
        </div>

        <Controller
          name={name}
          control={form.control}
          render={({ field }) => (
            <Switch
              checked={!!field.value}
              onChange={e => {
                const value = e.target.checked;
                if (value && withConfirmation) {
                  setDialog(true);
                } else {
                  onChange && onChange(e.target.checked);
                  field.onChange(value);
                }
              }}
              disabled={disabled || formDisabled}
              progress={progress}
            />
          )}
        />
      </div>
      {dialog && (
        <ConfirmationDialog
          title={confirmation.title}
          content={confirmation.content}
          confirmText={confirmation.confirmText}
          warningItems={confirmation.warningItems}
          onCancel={() => setDialog(false)}
          onConfirm={() => {
            onChange && onChange(true);
            form.setValue(name, true);
            setDialog(false);
          }}
        />
      )}
    </div>
  );
}
