import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";
import merge from "lodash/merge";
import { lensPath, over } from "ramda";

import { EmailSettingsProviderEnum, MFAMethod } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../../common/theme/theme";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import adminMfaMethodsApi from "../../../services/adminMfaMethodsApi";
import {
  getMfaMethodQueryKey,
  getMfaMethodsQueryKey,
  useGetMfaMethod,
  useGetMfaMethods,
} from "../../../services/adminMfaMethodsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import MessageProvidersEmailsTestMessage from "./MessageProvidersEmailsTestMessage";

export default function MessageProvidersEmails() {
  const [progress, setProgress] = useState(false);
  const tid = getTenantId();
  const queryClient = useQueryClient();
  const getMfaMethodsQuery = useGetMfaMethods(tid);
  const getMfaMethodEmailQuery = useGetMfaMethod(tid, "email");

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const defaultEmailSettings = getMfaMethodEmailQuery.data?.default_email_settings;

  const updateMfaMethod = (newMfaMethod: MFAMethod) => {
    setProgress(true);
    adminMfaMethodsApi
      .updateMfaMethod({ mfaID: "email", mFAMethod: newMfaMethod })
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodsQueryKey(tid) }))
      .then(() => queryClient.invalidateQueries({ queryKey: getMfaMethodQueryKey(tid, "email") }))
      .then(() => notifySuccess("Email configuration saved successfully"))
      .catch(handleErrorWithNotify("Error occurred while trying to update mfa methods"))
      .finally(() => setProgress(false));
  };

  const initialData = useMemo(() => {
    if (getMfaMethodsQuery.data) {
      return (getMfaMethodsQuery.data?.mfa_methods || []).find(v => v.id === "email") ?? {};
    }

    return {};
  }, [getMfaMethodsQuery.data]);

  const formFactory = useFormFactory({
    id: "mfa-email-delivery",
    data: initialData,
    noManagePermission: !checkTenantPermissionsQuery.data?.manage_mfa_methods,
    progress,
  });

  const provider = formFactory.watch("settings.email.provider");

  return (
    <FormContext.Provider value={formFactory.context}>
      <PageContent
        progress={
          getMfaMethodsQuery.isLoading ||
          getMfaMethodEmailQuery.isLoading ||
          checkTenantPermissionsQuery.isLoading
        }
        fullWidth={false}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Paper style={{ padding: 32 }}>
              <FormSection id="mfa-email-delivery-provider-label" title="Email configuration" />

              {formFactory.createFormCardsField({
                name: "settings.email.provider",
                cards: [
                  {
                    title: BRAND_NAME,
                    subtitle: `Send email using the ${BRAND_NAME} SMTP server`,
                    value: EmailSettingsProviderEnum.Embedded,
                  },
                  {
                    title: "Custom smtp",
                    subtitle: "Send emails using your own SMTP server",
                    value: EmailSettingsProviderEnum.Smtp,
                  },
                ],
              })}

              {provider === EmailSettingsProviderEnum.Smtp && (
                <>
                  {formFactory.createRadioGroupField({
                    name: "auth.email.auth",
                    title: "SMTP Auth mechanism",
                    values: [
                      { key: "plain", label: "Plain", value: "plain" },
                      { key: "crammd5", label: "CRAM-MD5", value: "crammd5" },
                    ],
                    rules: {
                      required: "Auth mechanism is required",
                    },
                  })}

                  {formFactory.createRequiredField({
                    name: "auth.email.host",
                    label: "SMTP host",
                  })}

                  {formFactory.createRequiredField({
                    name: "auth.email.port",
                    label: "Port",
                    type: "number",
                    valueAsNumber: true,
                    rules: {
                      validate: {
                        validNumeric: validators.validNumeric({ label: "Port" }),
                        notZero: v => {
                          if (v === 0) {
                            return "Port cannot be equal to 0";
                          }
                          return true;
                        },
                      },
                    },
                  })}

                  {formFactory.createRequiredField({
                    name: "auth.email.username",
                    label: "Username",
                    helperText: "Username which is used to authenticate to SMTP server",
                  })}

                  {formFactory.createRequiredField({
                    name: "auth.email.password",
                    label: "Password",
                    helperText: "Password which is used to authenticate to SMTP server",
                    defaultVisibility: false,
                    toggleVisibility: true,
                  })}
                </>
              )}

              {provider === EmailSettingsProviderEnum.Smtp && (
                <>
                  <FormSection id="mfa-email-settings-label" title="Email settings" />

                  {formFactory.createField({
                    name: "settings.email.custom_from",
                    label: "Sender friendly name",
                    placeholder:
                      defaultEmailSettings?.from || "SecureAuth <noreply@secureauth.com>",
                    rules: {
                      validate: {
                        isEmail: validators.validEmail({
                          label: "Sender friendly name",
                          customErrorMsg: `Allowed format is "John Doe <jdoe@secureauth.com>" or "jdoe@secureauth.com"`,
                          options: { allow_display_name: true, require_tld: false },
                        }),
                      },
                    },
                    optional: false,
                  })}
                </>
              )}

              {formFactory.createFormFooter({
                onSubmit: data =>
                  updateMfaMethod(
                    merge(
                      initialData,
                      over(
                        lensPath(["settings", "email", "custom_from"]),
                        customFrom => customFrom && customFrom.trim(),
                        data
                      )
                    )
                  ),
              })}
            </Paper>
          </Grid>

          <Grid item xs={12} lg={4}>
            <MessageProvidersEmailsTestMessage />
          </Grid>
        </Grid>
      </PageContent>
      <RouteLeavingGuard />
    </FormContext.Provider>
  );
}
