import React, { CSSProperties, ReactNode } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import TTLField from "../../../admin/components/common/TTLField";
import { useFormContext } from "../forms2/Form";

export interface DurationFieldProps {
  id?: string;
  name: string;
  label?: string;
  description: string | ReactNode;
  rules?: Pick<RegisterOptions, "required" | "validate">;
  style?: CSSProperties;
  disabled?: boolean;
  defaultValue?: string;
  withMilliseconds?: boolean;
}

export default function DurationField({
  id = "",
  name,
  label = "",
  description,
  rules,
  style = {},
  disabled,
  defaultValue = "0h0m0s",
  withMilliseconds,
}: DurationFieldProps) {
  const { form, disabled: formDisabled, id: formId } = useFormContext();
  const htmlId = id || formId;

  return (
    <Controller
      name={name}
      control={form.control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <TTLField
            id={htmlId}
            title={label}
            description={description}
            value={field.value}
            onChange={v => {
              field.onChange(v);
            }}
            errorText={fieldState.error?.message}
            style={style}
            disabled={disabled || formDisabled}
            withMilliseconds={withMilliseconds}
          />
        );
      }}
    />
  );
}
