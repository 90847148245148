import React, { useMemo, useState } from "react";

import { makeStyles } from "tss-react/mui";

import {
  Pool,
  PoolAuthenticationMechanismsEnum,
  PoolPreferredAuthenticationMechanismEnum,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import Dialog from "../../../../../common/components/Dialog";
import FormInputLabel from "../../../../../common/components/FormInputLabel";
import { FormContext } from "../../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../../common/utils/forms/formFactory";
import { validators } from "../../../../../common/utils/forms/validation";
import { useListSchemas } from "../../../../services/adminIdentitySchemasQuery";
import { useCheckTenantPermissions } from "../../../../services/adminTenantsQuery";
import IdentityPoolAuthenticationMechanisms from "../identityPool/IdentityPoolAuthenticationMechanisms";
import IdentityPoolSettingsAccordion from "../identityPool/IdentityPoolSettingsAccordion";
import { getOptionLabel, toAutocompleteOptions } from "../identityPoolsList/utils";
import {
  businessMetadataSchemaLabel,
  businessMetadataSchemaLabelTooltip,
  defaultBusinessMetadataSchemaId,
  metadataSchemaLabel,
  metadataSchemaLabelTooltip,
  payloadSchemaLabel,
  payloadSchemaLabelTooltip,
} from "../utils";

const useStyles = makeStyles()(() => ({
  checkboxes: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type InitialCreatePoolFormDataType = Omit<Pool, "tenant_id"> & {
  admin_initiated_registration: boolean;
};

export type CreatePoolFormDataType = {
  name: string;
  description: string | undefined;
  badge_color: string;
  public_registration_allowed: boolean;
  admin_initiated_registration: boolean;
  identifier_case_insensitive: boolean;
  payload_schema_id: string;
  metadata_schema_id: string;
  business_metadata_schema_id: string;
  preferred_authentication_mechanism: PoolPreferredAuthenticationMechanismEnum;
  authentication_mechanisms: PoolAuthenticationMechanismsEnum[];
};

const initialData: InitialCreatePoolFormDataType = {
  name: "",
  payload_schema_id: "default_payload",
  metadata_schema_id: "default_metadata",
  authentication_mechanisms: [PoolAuthenticationMechanismsEnum.Password],
  preferred_authentication_mechanism: PoolPreferredAuthenticationMechanismEnum.Password,
  public_registration_allowed: false,
  badge_color: "#BDBDBD",
  admin_initiated_registration: true,
  identifier_case_insensitive: true,
};

export interface IdentityPoolsCreateViewProps {
  handleCreate: (data: CreatePoolFormDataType) => void;
  handleClose: () => void;
  poolNames: string[];
  progress: boolean;
}

export default function IdentityPoolsCreateView({
  handleCreate,
  handleClose,
  poolNames,
  progress,
}: IdentityPoolsCreateViewProps) {
  const { classes } = useStyles();
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const tenantId = getTenantId();
  const schemasQuery = useListSchemas(tenantId);
  const schemaOptions = useMemo(
    () =>
      (schemasQuery.data?.schemas || []).map(s => ({
        value: s.id,
        name: s.name,
      })),
    [schemasQuery.data?.schemas]
  );
  const schemaAutocompleteOptions = toAutocompleteOptions(schemaOptions);

  const data = useMemo(
    () => ({
      ...initialData,
      business_metadata_schema_id:
        (schemasQuery.data?.schemas || []).find(sch => sch.id === defaultBusinessMetadataSchemaId)
          ?.id || null,
    }),
    [schemasQuery.data]
  );

  const formFactory = useFormFactory({
    id: "identity-pool-create",
    data,
    progress: progress || schemasQuery.isLoading || checkTenantPermissionsQuery.isLoading,
  });

  const handleAccordionChange = (panel: string) => (_: any, isExpanded: boolean) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const defaultBusinessMetadataSchemaExists = !!(schemasQuery.data?.schemas || []).find(
    sch => sch.id === defaultBusinessMetadataSchemaId
  );

  return (
    <Dialog id="identity-pool-create-dialog" title="Create Identity Pool" onClose={handleClose}>
      <FormContext.Provider value={formFactory.context}>
        {formFactory.createRequiredField({
          name: "name",
          label: "Name",
          autoFocus: true,
          rules: {
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespacesDashUnderscore({
                label: "Name",
              }),
              notUniq: v =>
                poolNames.map(s => s.toLowerCase()).indexOf(v.toLowerCase()) === -1 ||
                "Pool with a given name already exists",
            },
          },
        })}

        {formFactory.createField({
          name: "description",
          label: "Description",
          multiline: true,
          maxRows: 3,
          minRows: 3,
        })}

        {formFactory.createColorField({
          name: "badge_color",
          label: "Tag Color",
          defaultValue: "#BDBDBD",
        })}

        <IdentityPoolSettingsAccordion
          id="sign-in"
          label="Sign-in"
          onChange={handleAccordionChange("sign-in")}
          expanded={expandedAccordion === "sign-in"}
        >
          <IdentityPoolAuthenticationMechanisms
            formFactory={formFactory}
            progress={progress}
            noManagePermission={!checkTenantPermissionsQuery.data?.create_identity_pool}
          />

          <>
            <FormInputLabel id="signin-identifier-settings" label="Sign-in Identifier Settings" />
            {formFactory.createCheckBox({
              name: "identifier_case_insensitive",
              label: "Case Insensitive Identifiers",
              helperText:
                "When selected email and username case sensitivity will be ignored during user sign-in. This setting does not affect signup process.",
              style: {
                marginBottom: 0,
                padding: 24,
                border: "1px solid #ECECEC",
                borderRadius: 4,
              },
            })}
          </>
        </IdentityPoolSettingsAccordion>

        <IdentityPoolSettingsAccordion
          id="sign-up"
          label="Sign-up"
          onChange={handleAccordionChange("sign-up")}
          expanded={expandedAccordion === "sign-up"}
        >
          <FormInputLabel id="registration-modes" label="Registration Modes" />
          <div className={classes.checkboxes}>
            {formFactory.createCheckBox({
              name: "public_registration_allowed",
              label: "Self Registration",
              style: { marginBottom: 0 },
            })}

            {formFactory.createCheckBox({
              name: "admin_initiated_registration",
              label: "Admin Initiated Registration",
              disabled: true,
              style: { marginBottom: 0 },
            })}
          </div>
        </IdentityPoolSettingsAccordion>

        <IdentityPoolSettingsAccordion
          id="schema"
          label="Schema Settings"
          onChange={handleAccordionChange("schema")}
          expanded={expandedAccordion === "schema"}
        >
          {formFactory.createAutocompleteField({
            name: "payload_schema_id",
            label: payloadSchemaLabel,
            options: schemaAutocompleteOptions,
            getOptionLabel: getOptionLabel(schemaOptions),
            labelProps: {
              tooltip: payloadSchemaLabelTooltip,
            },
            rules: {
              required: `${payloadSchemaLabel} is required`,
            },
            loading: schemasQuery.isLoading,
          })}

          {formFactory.createAutocompleteField({
            name: "metadata_schema_id",
            label: metadataSchemaLabel,
            options: schemaAutocompleteOptions,
            getOptionLabel: getOptionLabel(schemaOptions),
            labelProps: {
              tooltip: metadataSchemaLabelTooltip,
            },
            rules: {
              required: `${metadataSchemaLabel} is required`,
            },
            loading: schemasQuery.isLoading,
          })}

          {formFactory.createAutocompleteField({
            name: "business_metadata_schema_id",
            label: businessMetadataSchemaLabel,
            options: schemaAutocompleteOptions,
            getOptionLabel: getOptionLabel(schemaOptions),
            labelProps: {
              tooltip: businessMetadataSchemaLabelTooltip,
            },
            rules: defaultBusinessMetadataSchemaExists
              ? {
                  required: `${businessMetadataSchemaLabel} is required`,
                }
              : {},
            loading: schemasQuery.isLoading,
          })}
        </IdentityPoolSettingsAccordion>

        {formFactory.createFormFooter({
          onCancel: handleClose,
          onSubmit: (data: CreatePoolFormDataType) => handleCreate(data),
          submitText: "Create",
        })}
      </FormContext.Provider>
    </Dialog>
  );
}
