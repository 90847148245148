import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { getTenantId } from "../../../../common/api/paths";
import Breadcrumbs from "../../../../common/components/Breadcrumbs";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { FormContext } from "../../../../common/utils/forms2/Form";
import { useFormFactory } from "../../../../common/utils/forms/formFactory";
import { validators } from "../../../../common/utils/forms/validation";
import adminBruteForceLimitsApi from "../../../services/adminBruteForceApi";
import {
  listBruteForceLimitsQueryKey,
  useListBruteForceLimits,
} from "../../../services/adminBruteForceLimitsQuery";
import { useGetEnvironment } from "../../../services/adminEnvironmentQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import { bruteForceTypeToName } from "./bruteForceProtection.utils";

const buildFullDuration = (d: string) => {
  let tmp = d;
  if (!d.includes("s")) {
    tmp = "0s";
  }
  if (!d.includes("m")) {
    tmp = "0m" + tmp;
  }
  if (!d.includes("h")) {
    tmp = "0h" + tmp;
  }

  return tmp;
};

export default function BruteForceProtectionByType() {
  const { type = "" } = useParams<{ type: string }>();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(false);

  const getEnvironmentQuery = useGetEnvironment();
  const envBruteForceLimits = useMemo(
    () => getEnvironmentQuery.data?.brute_force_limits || {},
    [getEnvironmentQuery.data]
  );
  const keys = useMemo(
    () => Object.keys(envBruteForceLimits).filter(k => k !== "enabled"),
    [envBruteForceLimits]
  );

  const queryClient = useQueryClient();
  const bruteForceLimitsQuery = useListBruteForceLimits(getTenantId());

  const customLimits = useMemo(
    () => bruteForceLimitsQuery.data?.brute_force_limits?.find(l => l.protected_type === type),
    [bruteForceLimitsQuery.data?.brute_force_limits, type]
  );

  const data = useMemo(
    () => ({
      block_duration: customLimits
        ? customLimits.block_duration
        : envBruteForceLimits[type]?.block_duration,
      max_attempts: customLimits
        ? customLimits.max_attempts
        : envBruteForceLimits[type]?.max_attempts,
    }),
    [customLimits, envBruteForceLimits, type]
  );

  const formFactory = useFormFactory({
    id: "brute-force-protection",
    data,
    progress,
  });

  const handleUpdate = data => {
    if (
      buildFullDuration(data.block_duration) !==
        buildFullDuration(envBruteForceLimits[type]?.block_duration) ||
      data.max_attempts !== envBruteForceLimits[type]?.max_attempts
    ) {
      setProgress(true);
      return adminBruteForceLimitsApi
        .setBruteForceLimit({
          bruteForceLimit: {
            block_duration: data.block_duration,
            max_attempts: data.max_attempts,
            protected_type: type,
            tenant_id: getTenantId(),
          },
        })
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listBruteForceLimitsQueryKey(getTenantId()) })
        )
        .then(() => notifySuccess("Brute-force Protection settings successfully updated"))
        .catch(
          notifyErrorOrDefaultTo(
            "Error occurred when trying to update brute-force protection settings"
          )
        )
        .finally(() => setProgress(false));
    }

    if (
      buildFullDuration(data.block_duration) ===
        buildFullDuration(envBruteForceLimits[type]?.block_duration) &&
      data.max_attempts === envBruteForceLimits[type]?.max_attempts &&
      customLimits
    ) {
      setProgress(true);
      return adminBruteForceLimitsApi
        .deleteBruteForceLimit({ protectedType: type })
        .then(() =>
          queryClient.invalidateQueries({ queryKey: listBruteForceLimitsQueryKey(getTenantId()) })
        )
        .then(() => notifySuccess("Brute-force Protection settings successfully updated"))
        .catch(
          notifyErrorOrDefaultTo(
            "Error occurred when trying to update brute-force protection settings"
          )
        )
        .finally(() => setProgress(false));
    }
  };

  useEffect(() => {
    if (!keys.includes(type)) {
      navigate("/brute-force-protection");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys, type]);

  return (
    <>
      <PageHeader title={bruteForceTypeToName(type)} breadcrumb={<Breadcrumb label={type} />} />
      <PageContent fullWidth={false}>
        <FormContext.Provider value={formFactory.context}>
          <Grid container>
            <Grid item lg={8} xs={12}>
              <Paper style={{ padding: 32 }}>
                {(data.block_duration === "0h0m0s" || data.max_attempts === 0) && (
                  <Alert severity="warning" style={{ marginBottom: 32 }}>
                    Brute-force protection for this type is disabled.
                  </Alert>
                )}

                {formFactory.createDurationField({
                  name: "block_duration",
                  label: "Block duration",
                  description: (
                    <span>
                      Duration until retry permitted. <code>0h0m0s</code> value will disable
                      protection.
                    </span>
                  ),
                  rules: {
                    validate: {
                      inRangeDuration: validators.inRangeDuration({
                        label: "Expiration",
                        min: "0h0m0s",
                        max: "1h0m0s",
                      }),
                    },
                  },
                })}

                {formFactory.createNumberField({
                  id: "max_attempts",
                  name: "max_attempts",
                  title: "Max attempts",
                  description: (
                    <span>
                      Number of attempts before blocked. <code>0</code> value will disable
                      protection.
                    </span>
                  ),
                  min: 0,
                  max: 100,
                })}

                {formFactory.createFormFooter({
                  onCancel: () => navigate("/brute-force-protection"),
                  onSubmit: handleUpdate,
                })}
              </Paper>
            </Grid>
          </Grid>
          <RouteLeavingGuard />
        </FormContext.Provider>
      </PageContent>
    </>
  );
}

const Breadcrumb = ({ label }: { label: string }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs
      items={[
        {
          key: "list",
          label: "Brute-force Protection",
          onClick: () => navigate(`/brute-force-protection`),
        },
        { key: "detail", label },
      ]}
    />
  );
};
