import React, { useCallback, useRef } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import Editor, { EditorProps, loader } from "@monaco-editor/react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Upload } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Button from "../../components/Button";
import FormInputLabel from "../../components/FormInputLabel";
import { formatXml } from "../../components/applications/SamlTab";
import { useFormContext } from "../forms2/Form";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

const useStyles = makeStyles()(theme => ({
  inputContainer: {
    border: `solid 1px ${theme.custom.greys.border}`,
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: "none",
  },
  labelContainer: {
    display: "flex",
    border: `solid 1px ${theme.custom.greys.border}`,
    background: "#f2f3f4",
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: "none",
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  label: {
    padding: "8px 26px",
    color: theme.palette.secondary.light,
    fontWeight: 600,
    fontSize: 12,
    flex: 1,
  },
  editor: {
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: 400,
        color: theme.palette.secondary.light,
      },
    },
  },
}));

export interface XMLEditorInputProps {
  name: string;
  label?: string;
  editorLabel?: string;
  editorProps?: EditorProps;
  rules?: Pick<RegisterOptions, "required" | "validate">;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const XMLEditorInput = ({
  name,
  label,
  editorLabel,
  editorProps,
  rules,
  disabled,
  onChange,
}: XMLEditorInputProps) => {
  const { cx, classes } = useStyles();

  const { form, id, disabled: formDisabled } = useFormContext();

  const fileInput = useRef<HTMLInputElement>(null);
  const handleFileUploadRequest = useCallback(() => {
    fileInput.current?.click();
  }, [fileInput]);

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = event => {
    form.clearErrors(name);
    const files = event.target.files;
    const file = files && files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async e => {
        const text = e?.target?.result ?? "";
        form.setValue(name, formatXml(text));
      };
      reader.readAsText(file);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth style={{ marginBottom: 32 }}>
      {label && <FormInputLabel id={`${id}-${name}-label`} label={label} />}
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            {editorLabel && (
              <div className={cx(classes.labelContainer, fieldState.error && classes.error)}>
                <div className={classes.label}>{editorLabel}</div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: 0,
                    borderTopRightRadius: 4,
                  }}
                  onClick={handleFileUploadRequest}
                  size="small"
                  disabled={formDisabled || disabled}
                >
                  <Upload size={16} style={{ marginRight: 6 }} /> Upload
                </Button>

                <input
                  type="file"
                  onChange={handleFileUpload}
                  ref={fileInput}
                  style={{ display: "none" }}
                  onClick={(event: any) => (event.target.value = null)}
                />
              </div>
            )}
            <div className={cx(classes.inputContainer, fieldState.error && classes.error)}>
              <Editor
                defaultLanguage="html"
                height="400px"
                options={{
                  minimap: {
                    enabled: false,
                  },
                  overviewRulerBorder: false,
                  lineDecorationsWidth: 0,
                  scrollBeyondLastLine: false,
                  readOnly: formDisabled || disabled,
                }}
                value={field.value}
                onChange={v => {
                  field.onChange(v ?? "");
                  onChange && onChange(v ?? "");
                }}
                wrapperProps={{
                  id,
                }}
                {...editorProps}
                className={classes.editor}
              />
            </div>
            {fieldState.error && (
              <FormHelperText style={{ minHeight: 20 }} error>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default XMLEditorInput;
