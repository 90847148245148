import React, { ReactNode, useCallback, useRef } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Trash2, Upload } from "react-feather";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { useFormContext } from "../forms2/Form";

const useStyles = makeStyles()(theme => ({
  input: {
    ".MuiInputBase-root": {
      backgroundColor: theme.custom.greys.fieldBackground,
    },
    ".MuiOutlinedInput-notchedOutline": {
      boxShadow: theme.custom.shadows.formInner,
    },

    "& .MuiOutlinedInput-root": {
      paddingRight: 0,
    },
  },
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  withValue: {
    "& .MuiOutlinedInput-input": {
      WebkitTextFillColor: "black",
    },
  },
}));

export interface UploadFieldProps {
  id?: string;
  title: string;
  name: string;
  rules?: Pick<RegisterOptions, "required" | "validate">;
  disabled?: boolean;
  optional?: boolean;
  onRemoveFile?: () => void;
  helperText?: string | ReactNode;
}

export default function UploadField({
  id,
  title,
  name,
  rules,
  disabled = false,
  optional,
  onRemoveFile,
  helperText,
}: UploadFieldProps) {
  const { form, disabled: formDisabled, id: formId } = useFormContext();
  const inputID = `${id || formId}-${name}`;

  const { cx, classes } = useStyles();

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileImportRequest = useCallback(() => {
    fileInput.current?.click();
  }, []);

  const handleUpload: React.ChangeEventHandler<HTMLInputElement> = event => {
    const files = event.target.files;
    const file = files && files[0];
    if (file) {
      form.setValue(name, file);
      form.trigger(name);
    }
  };

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{
        ...rules,
        validate: {
          required: v => optional || v || "File is required",
          ...rules?.validate,
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <div id={inputID} style={{ marginBottom: 32 }}>
            <div style={{ marginTop: 4 }}>
              <FormInputLabel id={`${inputID}-title`} label={title} optional={optional || false} />
              <TextField
                id={`${inputID}-input`}
                name="file"
                value={field.value?.name ?? "Upload file"}
                variant="outlined"
                disabled
                fullWidth
                error={!!fieldState.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {field.value ? (
                        <IconButton
                          onClick={() => {
                            field.onChange(null);
                            form.trigger(name);
                            if (onRemoveFile) {
                              onRemoveFile();
                            }
                          }}
                          disabled={formDisabled || disabled}
                        >
                          <Trash2 />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={handleFileImportRequest}
                          disabled={formDisabled || disabled}
                        >
                          <Upload />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                className={cx(
                  classes.input,
                  fieldState.error && classes.error,
                  field.value && classes.withValue
                )}
              />
              <FormHelperText id={`${inputID}-helper-text`} error={!!fieldState.error}>
                {fieldState.error?.message || helperText}
              </FormHelperText>

              <input
                type="file"
                onChange={handleUpload}
                ref={fileInput}
                style={{ display: "none" }}
                onClick={(event: any) => (event.target.value = null)}
              />
            </div>
          </div>
        );
      }}
    />
  );
}
