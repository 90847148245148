import React, { CSSProperties, useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import { Edit } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Policy } from "@cloudentity/acp-admin";

import { PolicySelection, Unrestricted } from "../../../admin/components/policies/PolicySelection";
import { useGetPolicy } from "../../../admin/services/adminPoliciesQuery";
import { FormContext, useFormContext } from "../forms2/Form";
import CommonTextField from "../forms/CommonTextField";
import { useFormFactory } from "./formFactory";

const useStyles = makeStyles()(theme => ({
  icon: {
    color: theme.palette.primary.main,
  },
}));

export interface PolicySelectionFieldProps {
  name: string;
  label?: string;
  labelIcon?: string;
  labelCaption?: string;
  type: string;
  open?: boolean;
  helperText?: string;
  target?: string | undefined;
  withProgress?: boolean;
  disabled?: boolean;
  optional?: boolean;
  workspaceId: string;
  onOpen?: () => void;
  onClose?: () => void;
  goBackButton?: boolean;
  style?: CSSProperties;
}

export const PolicySelectionField = ({
  name,
  label,
  labelIcon,
  labelCaption,
  type,
  open: isOpen,
  helperText,
  target,
  withProgress,
  disabled,
  workspaceId,
  onOpen,
  onClose,
  goBackButton,
  optional,
  style,
}: PolicySelectionFieldProps) => {
  const { classes } = useStyles();
  const { form, disabled: formDisabled, id } = useFormContext();
  const isDisabled = disabled || formDisabled;

  const formFactory = useFormFactory({ id: "fake" });

  const [open, setOpen] = useState(isOpen);
  const [previewOpen, setPreviewOpen] = useState(false);

  const policyId = form.watch(name);

  const getPolicyQuery = useGetPolicy(policyId, workspaceId, { enabled: !!policyId });
  const optionalValue = getPolicyQuery.data;
  const placeholder = Unrestricted;
  const value = optionalValue !== undefined ? optionalValue.policy_name : "";

  useEffect(() => {
    formFactory.setValue(name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, name]);

  const propsHidden = {
    name,
    optional,
    helperText,
    hideLabel: true,
    label,
    labelIcon,
    labelCaption,
    style: { ...style, display: "none" },
  };
  const propsVisible = {
    name,
    optional,
    helperText,
    label,
    labelIcon,
    labelCaption,
    style,
    placeholder,
    withProgress: withProgress || getPolicyQuery.isLoading,
    withEndAdornment:
      optionalValue && !isDisabled ? (
        <IconButton
          aria-label="link"
          data-testid={`policy-selection-field-presentation-edit-icon-${name}`}
          tabIndex={-1}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
            setPreviewOpen(true);
          }}
          size="large"
        >
          <Edit className={classes.icon} />
        </IconButton>
      ) : undefined,
    form: formFactory,
    id: id + "-presentation",
    inputProps: {
      "data-testid": `policy-selection-presentation-${name}`,
    },
    value,
    onClick: () => {
      if (!isDisabled) {
        setOpen(true);
        onOpen && onOpen();
      }
    },
  };

  const changePolicy = (policy?: Policy, isNewPolicy?: boolean) => {
    form.setValue(name, policy?.id);

    if (!isNewPolicy) {
      innerOnClose();
    } else {
      setPreviewOpen(true);
    }
  };

  const innerOnClose = () => {
    setOpen(false);
    setPreviewOpen(false);
    onClose && onClose();
  };

  return (
    <FormContext.Provider value={formFactory.context}>
      {CommonTextField(propsHidden)}
      {CommonTextField(propsVisible)}
      {open && isDisabled !== true && (
        <PolicySelection
          goBackButton={goBackButton}
          workspaceID={workspaceId}
          policy={optionalValue}
          onChange={changePolicy}
          previewOpen={previewOpen}
          onPreviewClosed={() => {
            if (previewOpen) {
              innerOnClose();
            }
          }}
          onClose={() => {
            innerOnClose();
          }}
          type={type}
          label={label}
          target={target}
        />
      )}
    </FormContext.Provider>
  );
};
